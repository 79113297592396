import { useEffect, useState } from 'react';
import { BusinessLocationMapStats } from '../types/types';
import { axiosInstance } from '../client';
import { ApiResponse } from '../types/api-types';
import { BASE_URL } from 'src/app/environment';
import { useAppAuth } from 'src/context/use-app-auth';

type Props = {
  keyword?: string;
  locationId?: string;
};

interface KeywordDataResponse {
  keywordsStats: BusinessLocationMapStats[];
  loading: boolean;
}

interface KeywordApiResponse extends ApiResponse {
  data: BusinessLocationMapStats[];
}

const useMapKeywordStats = ({
  keyword,
  locationId,
}: Props): KeywordDataResponse => {
  const { user } = useAppAuth();

  const [loading, setLoading] = useState(false);
  const [keywordsStats, setKeywordsStats] = useState<
    BusinessLocationMapStats[]
  >([]);

  const fetchKeywordStatsForMap = async () => {
    if (!locationId || !keyword) {
      return [];
    }

    setLoading(true);
    setKeywordsStats([]);

    try {
      const response = await axiosInstance.post<KeywordApiResponse>(
        `${BASE_URL}/users/fetchMapKeywordStats`,
        { locationId, userId: user?.key, keyword },
      );

      // Check response status
      setLoading(false);

      if (response.status === 200) {
        const data = response.data.data;

        if (data && data.length > 0) {
          setKeywordsStats(data);
        }
      } else {
        setKeywordsStats([]);
      }
    } catch {
      setKeywordsStats([]);
    }
  };

  useEffect(() => {
    if (!locationId || !keyword) {
      return;
    }

    fetchKeywordStatsForMap();
  }, [locationId, keyword]);

  return { loading, keywordsStats };
};

export { useMapKeywordStats };
