import React from 'react';

const MarkerIcon = ({
  fill = '#F44336',
  text = '',
  size = 24,
}: {
  fill?: string;
  text?: string;
  size?: number;
}) => {
  return (
    <svg
      width={size}
      height={size * (27 / 24)}
      viewBox="0 0 24 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5428 19.2089L13.8855 24.8663C13.3855 25.3658 12.7076 25.6464 12.0008 25.6464C11.2941 25.6464 10.6162 25.3658 10.1162 24.8663L4.4575 19.2089C2.9658 17.7172 1.94995 15.8165 1.53842 13.7474C1.12689 11.6783 1.33815 9.53363 2.14551 7.58458C2.95286 5.63554 4.32003 3.96966 6.07414 2.79762C7.82826 1.62558 9.89052 1 12.0002 1C14.1098 1 16.1721 1.62558 17.9262 2.79762C19.6803 3.96966 21.0475 5.63554 21.8548 7.58458C22.6622 9.53363 22.8734 11.6783 22.4619 13.7474C22.0504 15.8165 21.0345 17.7172 19.5428 19.2089Z"
        fill={fill}
        stroke="black"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <text
        x="12"
        y="15"
        textAnchor="middle"
        alignmentBaseline="middle"
        fontSize="10"
        fontWeight="bold"
        fill="white">
        {text}
      </text>
    </svg>
  );
};

export { MarkerIcon };
