import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
  label: string;
  date: string;
  disableFuture?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (newDate: Date) => void;
};

interface RangeProps {
  startLabel?: string;
  endLabel?: string;
  startDate: Date;
  endDate: Date;
  onDateRangeChange: (startDate: Date, endDate: Date) => void;
}

const SimplyDatePicker: React.FC<Props> = ({
  onChange,
  date,
  label,
  disableFuture,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        sx={{ minWidth: 150 }}
        disableFuture={disableFuture}
        defaultValue={dayjs(date)}
        label={label}
        onChange={newDate => {
          if (newDate) {
            onChange(newDate.toDate());
          }
        }}
        views={['month', 'year']}
      />
    </LocalizationProvider>
  );
};

const SimplyDateRangePicker: React.FC<RangeProps> = ({
  startLabel = 'Start Date',
  endLabel = 'End Date',
  startDate,
  endDate,
  onDateRangeChange,
}) => {
  const handleStartDateChange = (date: Dayjs | null) => {
    if (date) {
      const newStartDate = date.toDate();
      const adjustedEndDate = newStartDate > endDate ? newStartDate : endDate;
      onDateRangeChange(newStartDate, adjustedEndDate);
    }
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    if (date) {
      const newEndDate = date.toDate();
      if (newEndDate >= startDate) {
        onDateRangeChange(startDate, newEndDate);
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <DatePicker
          label={startLabel}
          value={dayjs(startDate)}
          onChange={handleStartDateChange}
          disableFuture
          maxDate={dayjs(endDate)}
          sx={{ minWidth: 150 }}
        />
        <DatePicker
          label={endLabel}
          value={dayjs(endDate)}
          onChange={handleEndDateChange}
          disableFuture
          minDate={dayjs(startDate)}
          sx={{ minWidth: 150 }}
        />
      </div>
    </LocalizationProvider>
  );
};

export { SimplyDatePicker, SimplyDateRangePicker };
