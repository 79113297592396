/* eslint-disable no-unused-vars */
// src/components/MetricsChart.tsx

import React, { useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAppAuth } from 'src/context/use-app-auth';
import { chartLabels, keywordLabels } from 'src/text/locales/default-en';
import { MetricsData, useMultiDailyMetrics } from 'src/api/hooks/use-metrics';
import { SimplyDateRangePicker } from 'src/components/date-picker';
import { SimplyChart } from 'src/components/charts';

const metricOptions: Array<{
  value: keyof MetricsData;
  label: string;
  text: string;
}> = [
  {
    value: 'callClicks',
    label: chartLabels.callClicks,
    text: chartLabels.callsText,
  },
  {
    value: 'mobileClicks',
    label: chartLabels.mobileImpressions,
    text: chartLabels.mobileImpressionsText,
  },
  {
    value: 'websiteClicks',
    label: chartLabels.websiteClicks,
    text: chartLabels.websiteText,
  },
  {
    value: 'directionClicks',
    label: chartLabels.businessDirections,
    text: chartLabels.businessDirectionsText,
  },
];

const MetricsChart: React.FC = () => {
  const { location, user } = useAppAuth();

  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date;
  });
  const [endDate, setEndDate] = useState(new Date());

  const locationId = location?.key ?? '';
  const accessToken = user?.token ?? '';

  const { loading, data } = useMultiDailyMetrics({
    locationId,
    accessToken,
    startDate,
    endDate,
  });

  const handleDateChange = (
    startDate: Date | undefined,
    endDate: Date | undefined,
  ) => {
    if (startDate) {
      setStartDate(startDate);
    }
    if (endDate) {
      setEndDate(endDate);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Box sx={{ width: '100%', height: 650 }}>
      <Typography mt={4} mb={4} variant="h3">
        {keywordLabels.performanceStats}
      </Typography>

      <SimplyDateRangePicker
        onDateRangeChange={handleDateChange}
        startDate={startDate}
        endDate={endDate}
      />

      <Box mt={4}>
        <SimplyChart metricOptions={metricOptions} data={data} />
      </Box>
    </Box>
  );
};

export { MetricsChart };
