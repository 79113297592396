import { SxProps, Theme } from '@mui/system';

const mergeStyles = (
  style1: SxProps<Theme>,
  style2?: SxProps<Theme>,
): SxProps<Theme> | undefined => {
  if (!style1 && !style2) return undefined;

  if (!style1) return style2;
  if (!style2) return style1;

  if (Array.isArray(style1) && Array.isArray(style2)) {
    return [...style1, ...style2];
  }

  if (Array.isArray(style1)) {
    return [...style1, style2];
  }
  if (Array.isArray(style2)) {
    return [style1, ...style2];
  }

  const style = {
    ...(style1 as SxProps<Theme>),
    ...(style2 as SxProps<Theme>),
  };
  return style;
};

function getColorCode(number: number) {
  if (number >= 1 && number < 5) {
    return '#61AE25';
  } else if (number >= 5 && number < 10) {
    return '#FFB300';
  } else if (number >= 10 && number < 15) {
    return '#F44336';
  } else if (number >= 15 && number <= 20) {
    return '#FC2010';
  } else {
    return '#FC2010';
  }
}

export { mergeStyles, getColorCode };
